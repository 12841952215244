import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import AuthRoute from './AuthRoute'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const EmailLogin = React.lazy(() => import('./views/pages/login/EmailLogin'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ResetPassword = React.lazy(() => import('./views/pages/ResetPassword/ResetPassword'))



class App extends Component {

  render() {
    return (
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/emaillogin" name="Email Login Page" element={<EmailLogin />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/resetPassword" name="Reset Password" element={<ResetPassword />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="*" name="Home" element={
              <AuthRoute>
                <DefaultLayout />
              </AuthRoute>
            } />
          </Routes>
        </Suspense>
      </BrowserRouter>
    )
  }
}

export default App;


//test