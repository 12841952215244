import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

const AuthRoute = ({ children }) => {
  const isLoggedIn = useSelector(state => state.isLoggedIn)[0]

  console.log(isLoggedIn, 'isLoggedIn')
  const isAuthenticated = isLoggedIn;

  return !isAuthenticated ? <Navigate to="/" replace /> : children
}

export default AuthRoute;
