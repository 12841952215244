import { createStore } from 'redux'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: true,
  userSites: [],
  emailResponseJson: [],
  isLoggedIn: [false],
  currSite: '',
  isAdmin: false,
  isSuperAdmin: false,
  companies: [],
  currCompany: '',
  siteSettings: true,
  updateSiteToggle: false,
  showForecastGraph: true,
  forecastData: "",
  forecastTabOn: true,
  fourteenDACreatedAt: [],
}

const persistConfig = {
  key: "root",
  storage: storage,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case 'add-site':
      return { ...state, userSites: rest }
    case 'set-config':
      return {
        ...state,
        emailResponseJson: rest
      }

    case 'set-login':
      return {
        ...state,
        isLoggedIn: rest
      }

    case 'set-site':
      return {
        ...state,
        currSite: rest
      }

    case 'set-company':
      return {
        ...state,
        currCompany: rest
      }

    case 'set-admin':
      return {
        ...state,
        isAdmin: rest
      }

    case 'set-super-admin':
      return {
        ...state,
        isSuperAdmin: rest
      }

    case 'set-companies':
      return {
        ...state,
        companies: rest
      }

    case 'set-site-settings':
      return {
        ...state,
        siteSettings: rest
      }

    case 'set-update-site-toggle':
      return {
        ...state,
        updateSiteToggle: rest
      }

    case 'show-forecast-graph':
      return {
        ...state,
        showForecastGraph: rest
      }

    case 'set-forecast-data':
      return {
        ...state,
        forecastData: rest
      }

    case 'logout':
      return initialState

    case 'set-forecast-tab-on':
      return {
        ...state,
        forecastTabOn: rest
      }

    case 'set-fourteenDACreatedAt':
      return {
        ...state,
        fourteenDACreatedAt: rest
      }
    default:
      return state
  }
}

const rootReducer = persistReducer(persistConfig, changeState);
export const store = createStore(rootReducer);
export const persistor = persistStore(store);
